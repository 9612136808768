/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

@import "~ngx-toastr/toastr";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// @import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import url(assets/scss/font_color.scss);
@import url(assets/scss/common.scss);

@font-face {
  font-family: "Nunito";
  src: url("/../assets/fonts/Nunito-Regular.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("/../assets/fonts/Nunito-SemiBold.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("/../../assets/fonts/Nunito-Bold.ttf");
  font-weight: 900;
  font-style: normal;
}
* {
  font-family: "Nunito" !important;
}

@font-face {
  font-family: "Poppins"; //This is what we are going to call
  src: url("../src/assets/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Quicksand"; //This is what we are going to call
  src: url("../src/assets/Quicksand/static/Quicksand-Regular.ttf");
}

@font-face {
  font-family: "Rubik"; //This is what we are going to call
  src: url("../src/assets/Rubik/static/Rubik-Regular.ttf");
}

video {
  height: 100% !important;
}

ion-modal.setting-modal {
  // --ion-background-color:#f12000;
  // --border-radius: 10px;
  // --max-height: 55%;
  // --max-width: 80%;
  // --width: 55%; // main
  --height: 72%;
  // --border-style: dotted;
  // --border-color: #428cff;
  // --border-width: 5px;
}

.modal-my-content{
  --background:#273075;
  height: 100%;
  width: 100%;
  
  &::part(content){
    --background:#82be4e35;
    width: 100%;

    
  }
  ion-content{
    // --background:#FF0000;
    width: 100%;
  }

}

.modal-my-content > .modal-wapper{
    width: 90%;
}


.mobile-input {
  border: hidden;
  outline: none;
  padding: 0px 10px;
  background: white;
  height: 100%;
  width: 100%;
}

.mobile-field {
  input {
    width: 100%;
    height: 90%;
  }
}

::ng-deep .mobile-field .item-native {
  padding: 0px !important;
}

.web-header {
  height: 70px;
  background: white;

  .navbar-toggler {
    // border-color: rgb(107 144 255 / 70%);
    border: hidden;
    padding: 0px 5px;
    .menu-icon {
      font-size: 2.3rem;
      color: blue;
    }

    .close-icon {
      color: blue;
      font-size: 2.3rem;
    }
  }

  .navbar-collapse {
    margin-left: calc(100vw - 77vw);
    margin-top: 4px;
    .navbar-nav {
      margin-right: 15px !important;
      align-items: center;
      margin-left: auto;
    }
    .nav-item {
      margin: 20px 5px;
    }

    .nav-link {
      text-transform: uppercase;
      // font-family: "Quicksand";
      font-size: small;
      font-weight: 700;
      color: #2f6982 !important;
    }
  }

  .container-fluid {
    background: white;
    .navbar-brand {
      margin-left: 2%;
    }
  }

  .social-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    div {
      margin: 0px 2px;
    }
  }

  .head-social-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #25a8e0;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      color: white;
      width: calc(100% - 35%);
      height: calc(100% - 35%);
    }
  }

  .icon-panel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    width: 15vw;
    border-left: 1px solid lightgrey;
  }

  @media (max-width: 991px) {
    .navbar-collapse {
      height: 100vh;
      margin-left: 28vw;
      margin-top: 4px;
      padding-top: 10px;
      display: block;
      padding-bottom: 30px;
      margin: auto;
    }

    .navbar-nav {
      align-items: center;
    }

    .icon-panel {
      // width: 25vw;
      width: auto;
    }
  }

  @media (max-width: 990px) {
    .nav-item {
      transform: skew(-21deg);
      width: max-content;
      .nav-link {
        transform: skew(21deg);
        padding: 10px 20px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        left: 0;
        background-color: lightgrey;
        opacity: 0;
        z-index: -1;
        transition: all 0.5s;
      }

      &:hover {
        color: white;
        &:before {
          left: 0;
          right: 0;
          opacity: 1;
          transition: all 0.5s;
        }
      }
    }
  }

  @media (min-width: 990px) {
    .nav-link {
      background-image: linear-gradient(
        transparent 0%,
        transparent 90%,
        #25a8e0 90%,
        #25a8e0 100%
      );
      background-repeat: no-repeat;
      background-size: 0% 100%;
      background-position-x: right;

      transition: background-size 300ms;

      &:hover {
        background-size: 100% 100%;
        background-position-x: left;
        border-radius: 5px;
      }
    }
  }

  @media (max-width: 740px) {
    .icon-panel {
      margin: 0px 25px;
      border: 0px hidden;
    }
  }

  @media (max-width: 600px) {
    .icon-panel {
      width: auto;
      margin: 55px 40px !important;
      border: 0px hidden;
    }
  }
}

.add-doctor-cr {
  .ng-select-single .ng-select-container {
    height: 50px;
  }
}
.ncb {
  color: red;
}

.has-error {
  color: var(--ion-color-danger);
  font-size: 13px;
}

.v-error {
  color: var(--ion-color-danger);
  font-size: 12px;
  text-align: center;
}
.subtext {
  font-size: 14px;
  color: #414d55;
}

.ng-select .ng-select-container {
  border: 1px solid #25a8e0 !important;
}

.submit-form-class {
  --width: 95%;
  --height: 90%;
  --border-radius: 10px;
}
.qrscan-modal {
  --border-radius: 10px;
}

.payment-model {
  --border-radius: 10px;
}

.s-chip {
  border-radius: 8px;
  background: #19e3cc;
  color: white;
  font-size: 14px;
}

.w-chip {
  border-radius: 8px;
  background: #ffd649;
  font-size: 14px;
  color: white;
}

.btn-default {
  font-size: 16px;
  color: #fff;
  margin: 0;
  text-transform: none;
  height: 45px;
  min-width: 130px;
  width: 100%;
  --box-shadow: none;
  background: #273075;
  --background: #273075;
  border-radius: 8px;
  &.btn-block {
    min-width: 100%;
    border-radius: 0px;
    --border-radius: 0px;
  }
  &.small {
    height: 40px;
    font-size: 13px;
  }

  &.square-btn {
    font-size: 9px;
    height: 31px;
    min-width: 106px;
    border-radius: 4px;
    --border-radius: 4px;
    --background: rgba(53, 160, 205, 0.84);
    background: rgba(53, 160, 205, 0.84);
  }
}

.table td,
.table th {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: normal;
  height: 40px !important;
  font-size: 12px;
  line-height: 14px;

  color: #90a1ac;
}

.table thead th {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  height: 40px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #414d55;
}

.f-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background {
  background: #f1f5f8;
  --background: #f1f5f8;
}
.presence-tooltip {
  text-transform: capitalize;
  font-size: 12px;
}

.d-card {
  background: #ffffff;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 100%;
}

.toolbar {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */

  color: #414d55;
}

input[type="date" i]::-webkit-calendar-picker-indicator {
  padding-inline-end: 10px;
}

input::-webkit-datetime-edit {
  margin-left: 10px;
}

// for appointment calendar

.monthview-selected {
  background-color: rgba(var(--ion-color-white-rgb), 0.3) !important;
  color: black !important;
}
.monthview-current,
.monthview-primary-with-event,
.monthview-secondary-with-event {
  background-color: white !important;
  color: black !important;
}

.full-calendar-popover {
  max-width: 380px;
  width: 380px;

  .popover-body {
    padding-right: 0;
  }
}

.call-interface {
  --border-radius: 6px;
  --height: 400px;
  --width: 300px;
  --background: #273075;
}
.monthview-datetable th {
  background-color: #f1f5f8;
  border-bottom: 0px !important;
}

fade {
  position: absolute;
  bottom: 0px;

  display: block;

  width: 100%;
  height: 50px;

  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 100%
  );
}

//for scroll bar

::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 2.5px;
}

::-webkit-scrollbar-thumb {
  background: #283b91;
}

.insurance-dropdown,
.speciality-dropdown {
  width: 100%;
  .ng-select-container {
    border-radius: 32px;
    background-color: #ffffff;
    border: 2px solid #25a8e0 !important;
    height: 48px !important;
    .ng-value-container .ng-placeholder {
      padding-bottom: 0px;
      padding-top: 4px;
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover,
.sc-ion-select-popover-h ion-label.sc-ion-select-popover {
  font-size: 14px;
  white-space: break-spaces;
}

// .referral-detail {
//   // --height: 250px;
// }

.selected {
  background: rgba(0, 144, 196, 0.07);
}
.header-title {
  font-size: 24px !important;
  height: 48px;
  line-height: 30px !important;
}

.patient-detail-modal {
  --height: 650px;
  --width: 966px;
  --border-radius: 5px;
}
.rating-modal {
  --height: 200px;
  --border-radius: 5px;
}
.item-s {
  max-width: 350px;
  --height: 36px;
  --border-radius: 8px;
  border-radius: 8px;
  height: 36px;
  display: flex;
  --background: #f7f1f1;
  --box-shadow: none;
  box-shadow: none;
  margin-left: auto;
  .searchbar-search-icon {
    height: 18px;
    width: 18px;
    top: 10px;
  }
  .searchbar-input {
    line-height: 26px;
  }
}

::-webkit-calendar-picker-indicator {
  filter: brightness(0);
}
.weekview-normal-event-container {
  margin-top: 106px !important;
}
.calendar-event {
  position: initial !important;
  height: auto !important;
  width: 100% !important;
}
ion-chip.event-chip {
  height: auto;
}
.confirm-alert {
  --max-width: 500px;
  --max-height: 200px;
  --height: 160px;
}

.invite-doctor {
  --max-width: 50%;
  --max-height: 330px;
  --height: 330px;
  --border-radius: 10px;
}

.buy-membership {
  --border-radius: 10px;
  --height: 550px;
}

@media (max-width: 770px) {
  .invite-doctor {
    --min-width: 70%;
    --min-height: 380px !important;
    --height: 390px !important;
  }

  .buy-membership {
    --height: 650px;
  }
}

@media (max-width: 568px) {
  .confirm-alert {
    --max-width: 300px;
    --max-height: 250px;
    --height: 180px;
  }
}
@media (max-width: 400px) {
  .confirm-alert {
    --max-width: 280px;
    --max-height: 260px;
    --height: 190px;
  }
}

.status-chip {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  margin-left: 5px;
}

.completed {
  background: #cbe4cb;
  color: #1a8f1a;
}

.refunded {
  background: #b2cdec;
  color: #063688;
}

.rejected {
  background: #f2ecec;
  color: #fc0505;
}

.cost-added {
  background: #a6d1e4;
  color: #183acc;
}

.in-progress {
  background: #f5f7d3;
  color: #97a630;
}
.payment-modal {
  --width: 35%;
  --height: 60%;
  --border-radius: 25px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.sc-ion-alert-md-h {
  --min-width: 300px;
}
.item-input.sc-ion-label-md-h,
.item-input .sc-ion-label-md-h {
  flex: auto;
  max-width: none;
}
