.d-card {
  background: #ffffff;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 100%;
}

.card-action {
  font-size: 18px;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.y-name {
  font-size: 13px !important;
  font-weight: 600 !important;
}
