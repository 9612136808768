.toolbar-name {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  color: #414d55;
}

.header {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #414d55;
}
